<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { degree, typeOfInstitution, languageTest, majorStage } from '@/utils/public';

export default {
  name: "serviceItems",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/clientUser/caseManagement/getList?orgShopId=' + this.$route.query.id,
        belongTo: '当前机构：' + this.$route.query.other,
        filter: {
          // initUrl: '/agency/institutionsEmployee/list/get',
          controls: [
            {
              key: 'fullName',
              label: '学员姓名',
              type: 'text'
            },
            {
              key: 'applyingToASchool',
              label: '录取学校',
              type: 'text'
            },
            {
              key: 'homePageDisplay',
              label: '是否展示在官网',
              type: 'select',
              config: {
                options: [
                  {
                    text: '否',
                    value: 0
                  },
                  {
                    text: '是',
                    value: 1
                  }
                ]
              }
            },
            {}
          ]
        },
        columns: [
          {
            key: 'fullName',
            title: '学员姓名',
            width: '100px',
          },
          {
            key: 'academicDegree',
            title: '学位',
            width: '100px',
          },
          {
            key: 'universityOneIsGraduatedFrom',
            title: '毕业院校',
            width: '100px',
          },
          {
            key: 'typeOfInstitution',
            title: '院校类型',
            width: '100px',
          },
          {
            key: 'gpa',
            title: 'GPA',
            width: '100px',
          },
          {
            key: 'applyingToASchool',
            title: '录取学校',
            width: '100px',
          },
          {
            key: 'applyingForADegree',
            title: '申请学位',
            width: '100px',
          },
          {
            key: 'numberOfRetainedFunds',
            title: '预约数',
            width: '100px',
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '80px'
          }
        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        checkbox: true,
        actions: [
          {
            key: 'edit',
            text: '新建案例',
            type: 'form',
            permission: '/clientUser/caseManagement/insert',
            config: {
              color: 'primary',
              title: '新增录取案例',
              initUrl: "/clientUser/caseManagement/getSchoolList?orgShopId=" + this.$route.query.id,
              submitUrl: "/clientUser/caseManagement/insert",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'fullName',
                  label: '姓名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'academicDegree',
                  label: '学位',
                  type: 'select',
                  required: true,
                  config: {
                    options: degree
                  }
                },
                {
                  key: 'universityOneIsGraduatedFrom',
                  label: '毕业院校',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'typeOfInstitution',
                  label: '院校类型',
                  type: 'select',
                  required: true,
                  config: {
                    options: typeOfInstitution
                  }
                },
                {
                  key: 'major',
                  label: '专业',
                  type: 'text',
                },
                {
                  key: 'gPA',
                  label: 'GPA',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'languageExamTypes',
                  label: '语言考试类型',
                  type: 'select',
                  required: true,
                  config: {
                    options: languageTest
                  }
                },
                {
                  key: 'languageAchievement',
                  label: '语言成绩',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'applyingToASchool',
                  label: '申请学校',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'data',
                    textKey: 'schoolName',
                    valueKey: 'schoolName'
                  }
                },
                {
                  key: 'applyingForADegree',
                  label: '申请学位',
                  type: 'select',
                  required: true,
                  config: {
                    options: majorStage
                  }
                },
                {
                  key: 'applyingForAMajor',
                  label: '申请专业',
                  type: 'text',
                },
                {
                  key: 'applyTeacher',
                  label: '申请老师',
                  limitNum: 3,
                  type: 'mSelect',
                  config: {
                    options: 'applyTeacher'
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'offer',
                  label: 'offer图片',
                  tips: "请上传比例为 338:365 ；大小不超过5mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000 * 5
                  }
                },
                {},
                {
                  key: 'caseName',
                  label: '案例名',
                  type: 'text',
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {
                  key: 'serviceHighlights',
                  label: '服务亮点',
                  type: 'text',
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {
                  key: 'serviceType',
                  label: '服务类型',
                  type: 'text',
                },
                {
                  key: 'placements',
                  label: '展示位置',
                  type: 'radio',
                  config: {
                    options: [
                      {
                        text: '网站顶部',
                        value: 'TOP'
                      },
                      {
                        text: '网站底部',
                        value: 'BOTTOM'
                      },
                    ]
                  }
                },
                {
                  key: 'caseIntroduction',
                  label: '案例介绍',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 1, max: 500, message: '请尽量精简内容，输入500字以内' }
                    ]
                  }
                },
                {
                  key: 'homePageDisplay',
                  label: '显示在官网首页',
                  type: 'radio',
                  config: {
                    initialValue: 0,
                    options: [
                      {
                        text: '否',
                        value: 0
                      },
                      {
                        text: '是',
                        value: 1
                      },
                    ]
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'edit',
            text: '删除',
            icon: 'plus',
            type: 'dialog',
            isSelected: true,  // 是否必勾选
            determine: true,  // 是否需要判定条
            permission: '/clientUser/caseManagement/delete',
            modalObj: (rowData, tableData) => {
              return `您共选择 ${rowData.length} 条录取案例，是否全部删除? `
            },
            config: {
              title: '删除成功',
              submitUrl: '/clientUser/caseManagement/delete',
              submitText: '确认',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/clientUser/caseManagement/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/clientUser/caseManagement/get",
              submitUrl: "/clientUser/caseManagement/update",
              submitText: '保存',
              controls: [
                {
                  key: 'institutionId',
                  label: '机构名',
                  type: 'select',
                  required: true,
                  isDisabled: (row, key) => {
                    return true
                  },
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'fullName',
                  label: '姓名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'academicDegree',
                  label: '学位',
                  type: 'select',
                  required: true,
                  config: {
                    options: degree
                  }
                },
                {
                  key: 'universityOneIsGraduatedFrom',
                  label: '毕业院校',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'typeOfInstitution',
                  label: '院校类型',
                  type: 'select',
                  required: true,
                  config: {
                    options: typeOfInstitution
                  }
                },
                {
                  key: 'major',
                  label: '专业',
                  type: 'text',
                },
                {
                  key: 'gpa',
                  label: 'GPA',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'languageExamTypes',
                  label: '语言考试类型',
                  type: 'select',
                  required: true,
                  config: {
                    options: languageTest
                  }
                },
                {
                  key: 'languageAchievement',
                  label: '语言成绩',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'applyingToASchool',
                  label: '申请学校',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'list',
                    textKey: 'schoolName',
                    valueKey: 'schoolName'
                  }
                },
                {
                  key: 'applyingForADegree',
                  label: '申请学位',
                  type: 'select',
                  required: true,
                  config: {
                    options: majorStage
                  }
                },
                {
                  key: 'applyingForAMajor',
                  label: '申请专业',
                  type: 'text',
                },
                {
                  key: 'applyTeacher',
                  label: '申请老师',
                  limitNum: 3,
                  type: 'mSelect',
                  config: {
                    options: 'applyTeacher'
                  }
                },
                {
                  key: 'sort',
                  width: '130px',
                  label: '排序',
                  type: 'numeric',
                  config: {
                    format: 'sort'
                  }
                },
                {
                  key: 'offer',
                  label: 'offer图片',
                  tips: "请上传比例为 338:365 ；大小不超过5mb的图片",
                  type: 'file',
                  config: {
                    uploadUrl: '/api/clientUser/institutionalShop/addUpload',
                    format: "image",
                    accept: ["jpeg", "png", 'jpg'],
                    maxSize: 1024 * 1000 * 5
                  }
                },
                {},
                {
                  key: 'caseName',
                  label: '案例名',
                  type: 'text',
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {
                  key: 'serviceHighlights',
                  label: '服务亮点',
                  type: 'text',
                  config: {
                    rules: [
                      { min: 1, max: 50, message: '请尽量精简内容，输入50字以内' }
                    ]
                  }
                },
                {
                  key: 'serviceType',
                  label: '服务类型',
                  type: 'text',
                },
                {
                  key: 'placements',
                  label: '展示位置',
                  type: 'radio',
                  config: {
                    options: [
                      {
                        text: '网站顶部',
                        value: 'TOP'
                      },
                      {
                        text: '网站底部',
                        value: 'BOTTOM'
                      },
                    ]
                  }
                },
                {
                  key: 'caseIntroduction',
                  label: '案例介绍',
                  type: 'textarea',
                  config: {
                    rules: [
                      { min: 1, max: 500, message: '请尽量精简内容，输入500字以内' }
                    ]
                  }
                },
                {
                  key: 'homePageDisplay',
                  label: '显示在官网首页',
                  type: 'radio',
                  required: true,
                  config: {
                    options: [
                      {
                        text: '否',
                        value: 0
                      },
                      {
                        text: '是',
                        value: 1
                      },
                    ]
                  }
                },
                {}
              ]
            }
          },
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>